@import url('https://fonts.googleapis.com/css2?family=Archivo+Black&family=Roboto:wght@400;500;700;900&display=swap');


.herobg{
    background-image:linear-gradient(to right,rgba(0, 0, 0, 0.576),rgba(0, 0, 0, 0.576)), url(../../Assets/herobg\ \(2\).jpg);
    width: 100%;
    height: 90vh;
    background-repeat: no-repeat;
    background-size: cover;
    position: relative;
    background-attachment: fixed;
    display: flex;
    flex-direction: column; 

}
.p1{
 position: absolute;
 color: white;
 font-family: 'Roboto', sans-serif;
 top: 10rem;
 left: 5rem;
 font-size: 1.1rem;

}
.p2{
    position: absolute;
    color: white;
    font-family: 'Archivo Black', sans-serif;
    top: 10rem;
    left: 5rem;
    font-size: 3.5rem;
   
   
   }
   .p3{
    position: absolute;
    color: white;
    font-family: 'Roboto', sans-serif;
    top: 16rem;
    left: 5rem;
    font-size: 1.4rem;

   }
   .btn{
    width: 10rem;
    position: absolute;
    top: 23rem;
    left: 5rem;
    background: none;
    border: 2px solid red;
    border-radius: 1rem;
    padding: 1rem;
   }
   .btnlink{
    color: white;
    text-decoration: none;
    font-weight: 700;
    letter-spacing: .5px;
    font-size: 1.1rem;
   }
   .btn:hover{
    background: white;
    border: 0;
   }
   .btn:hover .btnlink{
    color: red;
   }


@media screen and (max-width:768px)
{

    
.herobg{
    background-image:linear-gradient(to right,rgba(0, 0, 0, 0.576),rgba(0, 0, 0, 0.576)), url(../../Assets/herobg\ \(2\).jpg);
    width: 100%;
   
    background-repeat: no-repeat;
    background-size: cover;
    position: relative;
    background-attachment: fixed;
    display: flex;
    flex-direction: column; 

}

.p1{
    position: absolute;
    color: white;
    font-family: 'Roboto', sans-serif;
    top: 9rem;
    left: 3rem;
    font-size: 1.2rem;
   
   }
    .p2{
        position: absolute;
        color: white;
        font-family: 'Archivo Black', sans-serif;
        top: 10rem;
        left: 5rem;
        font-size: 2rem;
        margin-top:2rem;
        margin-left:-2rem
       
       }
       .p3{
        position: absolute;
        color: white;
        font-family: 'Roboto', sans-serif;
        top: 17rem;
        left: 3rem;
        font-size: 1.2rem;
       
       
       }
       .btn{
        width: 10rem;
        position: absolute;
        top: 23rem;
        left: 3rem;
        background: none;
        border: 2px solid red;
        border-radius: 1rem;
        padding: 1rem;
       }
}