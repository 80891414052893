@import url('https://fonts.googleapis.com/css2?family=Archivo+Black&family=Roboto:wght@400;500;700;900&display=swap');


.Aa h1{
    margin-top: 20rem;
}
.Aa{
    text-align: center;
    font-size: 2rem;
    font-family: 'Roboto', sans-serif;

}

.AA{
    text-align: center;
    font-size: 1.1rem;
    font-family: 'Poppins';  
    padding-left:3rem;
    padding-right:3rem;
    line-height: 2rem;
}



.bg{
    height: 30rem;
    width: 100%;
    opacity: 4;
}
.para{
    margin-top: -20rem;
    color:white;
   
    font-size: 320%;
    text-align: center;
    margin-bottom: 20rem;
}

.hr{
    height: 12rem;
    width: 0.4rem;
    margin-top: -22rem;
    margin-left: 21rem;
    margin-right: -25rem;
    background-color: white;
}
.img{
    margin-top: -15rem;
    margin-left: 4rem;
    
}
.paraH{
    text-align: center;
    color: red;
    margin-top: 5rem;
}
.txt{
    text-align: center;
    font-size: 1.3rem;
    margin-left: 5rem;
    margin-right: 5rem;
}
.icon{
  background-color: aqua;
  height: 10rem;
  width: 10rem;
  margin-top: 5rem;

}
.card{
    display: flex;
    justify-content: center;
    gap: 2rem;
   
    
}

.c1{
    height: 17rem;
    width: 15rem;
    background-color:white;
    box-shadow: 0 0 0.2rem;
    color:grey;
    margin-top: 1rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    place-items: center;
    text-align: center;

}
.c1 p{
    color: gray;
    font-weight: bold;
    font-family: Helvetica, sans-serif;
    font-size: .9rem;
}
.am{
    width: 4rem;
}

.big{
    display: flex;
    justify-content: space-around;
    padding:5rem;
    width:100%;
}

.left{

    width:100%;
   
}
.paraL{
    font-size: 0.9rem;
    font-family: 'Poppins'; 
    color: gray; 
}
.A{
    color: black;
    font-weight: bolder;
    font-size: 1.8rem;

    text-align: center;
  
   
    
}
.ceo{
    color: red;
    font-weight: bold;
}

.flex{
    width:100%;padding: 1rem;
    display: flex;
    justify-content: center;
}
.A2{
  
    width: 85%;
    height: 85%;
 /*   border-top-right-radius: 10rem;
    border-bottom-left-radius: 10rem;
    font-family: 'Poppins';  */
}
.paraS{
    font-size: 0.9rem;
    font-family: 'Poppins';  
}
.btn{
    height: 2rem;
    width: 8rem;
    background-color: red;
    color: white;
    border: none;
    margin-top: 1rem;
}
.two{
    display: flex;
    justify-content: center;
    margin-top: 1rem;
    gap: 3rem;
}
.paraK{
    margin-right: 3rem;
    
    
}
.B{
    width: 4rem;
}
.h3{
    color: red;
}

.kkk1{
    
   
   height: 30rem;
   width: 35rem;
  /* border-bottom-right-radius: 8rem;
   border-top-left-radius: 8rem;*/
   margin-top: 1rem;
   
 
 
}

.img1{
    margin-left: 3rem;
    margin-top: 5rem;
    display: flex;
}
.li{
    line-height: 1.5rem;
    margin-right: 6rem;
    font-weight: bolder;
    margin-left: 3rem;
    text-align: left;
}
.A1{
    background-color: bisque;
    border-radius: 50%;
    height: 5rem;
    width: 5rem;
    margin-top: 4rem;
    
}
.side{
    margin: 1.4rem;
}
.card3{
    display: flex;
}



@media screen and (max-width:768px)
{

    .card{
        display: flex;
        flex-direction: column;
        justify-content: center;
        gap: 2rem;
        place-items: center;
       
        
    }

    .big{
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        padding:2rem;
        margin-left: -2rem;
        width:98%;
        text-align: center;
    }
    .left{

        padding:1rem;
        width:95%;
       
    }
    
}