@import url('https://fonts.googleapis.com/css2?family=Archivo+Black&family=Roboto:wght@400;500;700;900&display=swap');

.Cmain{
    margin-top: 5.5rem;
}
.top{
    margin-left: 0rem;
}
.image{
    width: 100%;
    height:43rem;
}
.flex{
    display: flex;
    margin-top: -39rem;
    gap: 5rem;
    justify-content: center;
    color: white;
    padding: 2rem;

    
    
}
.para1{
    margin-top: 5rem;
}
.bg input{
   
    flex-direction: column;
    height: 0.2rem;
    width: 20rem;
 
    padding: 1rem;
   
    
}
.bg{
    background-color: rgb(54, 66, 66);
    padding: 1rem;
    margin-top: -2rem;
}
.btn{
    height: 2rem;
    width: 6rem;
    margin-top: 1rem;
}
.add{
    display: flex;
   
    margin-top: 3rem;
  
    justify-content: space-around;
    text-align: center;
    font-family: 'Poppins',sans-serif;
    font-weight: bolder;
    
}
.visit {
    text-align: center;
    text-decoration: none;
   
    
}
.micon{
    height: 2rem;
    width: 2rem;
    margin-left: 0rem;
}
.picon{
    height: 2rem;
    width: 2rem;
    margin-left: 0rem;
}
.vicon{
    height: 2rem;
    width: 2rem;
    margin-left: 0rem;
}
.frist a{
    text-decoration: none;
    color: black;
}
.second a{
    text-decoration: none;
    color: black;
}
.third a{
    text-decoration: none;
    color: black;
}
.map{
    place-items: center;
    height: 30rem;
    width: 70%;
    border:2px solid brown;
    padding:1rem;
}


.bottom{
    display: flex;
    justify-content: center;
    padding:2rem;

}

@media screen and (max-width:768px) {

    .image{
        width: 100%;
        height:55rem;
    }

    .flex{
        display: flex;
        flex-direction: column;
        margin-top: -60rem;
        gap: 5rem;
        justify-content: center;
        color: white;
        padding: 2rem;
    
        
        
    }

    .bg input{
   
        flex-direction: column;
        height: 0.2rem;
        width: 15rem;
     
        padding: 1rem;
       
        
    }

    .bg textarea{
   
     
        width: 17rem;
     
   
       
        
    }

    .add{
        display: flex;
       flex-direction: column;
        margin-top: 3rem;
      
        justify-content: space-around;
        text-align: center;
        font-family: 'Poppins',sans-serif;
        font-weight: bolder;
        
    }
    .visit {
        text-align: center;
        text-decoration: none;
       
        
    }
    .map{
        place-items: center;
        height: 15rem;
        width: 80%;
        border:2px solid brown;
        padding:1rem;
    }
    
}