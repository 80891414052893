.head {
    font-family: 'Ubuntu';
  justify-content: center;
    text-align: center;
    font-weight: 600; 
    font-size: 1.5rem;


}
.hold h1{
  font-size: 2rem;
  font-family: 'Roboto', sans-serif;
}
