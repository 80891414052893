@import url('https://fonts.googleapis.com/css2?family=Archivo+Black&family=Roboto:wght@400;500;700;900&display=swap');


.row{
    display:flex;
    flex-direction: row;
    justify-content: center; 
}

.head{

  font-family: 'Roboto', sans-serif;
  justify-content: center;
    text-align: center;
    font-weight: 600; 
    font-size: 2rem;
    text-transform: capitalize;
}
.lnkp{
  text-decoration: none;
  color: black;
}

@media screen and (max-width:768px) {

  .row{
    display:flex;
    flex-direction: column;
    justify-content: center; 
    place-items: center;

}
}


