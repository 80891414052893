

.up{
    padding:1rem;
    height:15.5rem;
width:100%;
z-index:1;
}

.upeer
{
margin-left: 18%;
height:15rem;
width:50%;
border:3px solid red;
background-image: url(./../../Assets/orvarsuprem.webp);
background-repeat: no-repeat;
background-position: center;
background-size: contain;

z-index:3;

}



.fh
{
    display: flex;
    flex-direction: row;
    justify-content: center;
    padding:1rem;
height: min-content;
    position: relative;

}

.down{
    width:80%;
    height: min-content;
    padding:1.5rem;
  
   height: 18rem;
    z-index: 6;
    background-color: white;
    transform: translateY(-4rem);
    border:3px solid green;
  
 
}

.right{
    width:60%;
   

    transform: translate(-1rem,-9rem);
    z-index: 8;
    background-color: white;
    border:3px solid  black;
    height: 18rem;
    display: block;

    justify-content: space-around;

 
}


.db{
    font-size: 1.1rem;
    font-family: 'Poppins';  
    margin-bottom: 1rem;
    text-align: center;
  
}
.nine{
    color: red;
}

.para1{
    font-size: 1rem;
}


.ana{
    font-size: 1.1rem;
    text-align: center;
}

.i1{
height: 4rem;
width:4rem;
}

.rj1,.rj2{

    position: relative;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    padding:0.5rem;
}

.rjt{

    text-align: center;
    color:black;
}

.rj1{
    border-bottom: 2px solid Green;
}


.rj2{
    border-top: 2px solid Green;
}





@media screen and (max-width:768px)
{

    
.up{
   

width:100vw;
z-index:1;
}

.upeer
{
    margin-left: -0rem;
height:15rem;
width: 90vw;;
border:3px solid red;
background-image: url(./../../Assets/orvarsuprem.webp);
background-repeat: no-repeat;
background-position: center;
background-size: contain;

z-index:3;

}


.fh
{
    display: flex;
    flex-direction: column;
    justify-content: center;

height: min-content;
    position: relative;

}

.down{
    width:82vw;
    height: auto;
    padding:1rem;
  
   height: 32rem;
    z-index: -1;
    background-color: white;
    transform: translateY(1rem);
    border:3px solid green;
  text-align: center;
 
}

.right{
    width:98%;
   

    transform: translate(0rem,2rem);
    z-index: 8;
    background-color: white;
    border:3px solid  black;
    height: 18rem;
    display: block;

    justify-content: space-around;

 
}


}